import { ActivityType, MinimalActivity, ActivityProgress, NovoAIErrorCodes } from './activity';
import { Comment } from './comment';
import { NovoAIItemType } from './lecture-page';
import { TeamSet, User } from './my-account';
import { PointsConfiguration } from './points-configuration';

export interface Forum {
  id: number;
  locked: boolean;
  released: boolean;
  title: string;
}

export interface AIDiscussionPrompts {
  loading: boolean;
  errorCode: NovoAIErrorCodes | null;
  prompts: {
    id: number;
    text: string;
  }[];
}

export const PUSHER_DISCUSSION_INSIGHTS = 'key_themes_requested';

export interface AIDiscussionInsights {
  keyThemes: {
    loading: boolean;
    subscribeToPusher: boolean;
    data: string[];
    errorCode: NovoAIErrorCodes | null;
  };
  perspectives: {
    loading: boolean;
    similar: Partial<Comment>;
    different: Partial<Comment>;
    errorCode: NovoAIErrorCodes | null;
  };
}

export const errorCodesWithAlert: Set<NovoAIErrorCodes | string> = new Set([
  NovoAIErrorCodes.BLANK,
  NovoAIErrorCodes.TOO_SHORT,
]);

export interface DiscussionTeam {
  id: number;
  name: string;
  profilePicture: string;
  textDescription: string;
  pusherChannelName: string;
  teamSet: TeamSet;
}

export interface DiscussionMember {
  id: number;
  isAdmin: boolean;
  approved: boolean;
  createdAt: string;
  joinedAt: string;
  newContributions: number;
  takenBack: boolean;
  teamId: number;
  teamName: string;
  totalContributions: number;
  user: User;
  visitedWorkspaceAt: string;
  team: DiscussionTeam
}

export type Post = {
  id: number;
  title: string;
  type: ActivityType.POST,
  releaseDate: string;
  totalPoints: number[];
  pointsReceived: number;
  pointsConfiguration: PointsConfiguration;
  isRequiredForCompletion: boolean;
  isTodo: boolean;
  progress: ActivityProgress;
  activityType: string;
  body: string;
  createdAt: string;
  expandedOnLoad: boolean;
  flagged: boolean;
  followed: boolean;
  forum: Forum;
  forumId: number;
  highlighedFrom: string;
  highlightedTo: string;
  highlightedNow: boolean;
  isNew: boolean;
  numPostsAndComments: number;
  posted: boolean;
  postsCount: number;
  released: boolean;
  starterId: number;
  commentIds: number[];
  votesCount: number;
  user: User;
  additionalNewCommentsBeforeCount: number;
  additionalCommentsBeforeCount: number;
  additionalNewCommentsAfterCount: number;
  additionalCommentsAfterCount: number;
  teamDiscussionMembers?: DiscussionMember[];
  team: DiscussionTeam;
  teamSet: TeamSet;
  commentsFetched: boolean;
  commentedByUser: boolean;
  leaderboardPoints: number;
  leaderboardRank: number;
  priorLeaderboardRank: number;
  vieweeLastActivity: string;
  bookmarkId?: number;
  aiOrigin?: NovoAIItemType;
  keyThemes?: string[];
  hasAiInsights?: boolean;
  hasMinValidCommentsForKeyThemes?: boolean;
} & MinimalActivity;

export interface PostsNormalized { [id: string]: Post }
