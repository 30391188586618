import React from 'react';
import { css } from '@emotion/react';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { baseComponentStyles } from 'lecture_pages/directives/components/base-lecture-component';
import GradientAiIcon from 'lecture_pages/components/left-panel/novo-ai/components/shared/gradient-ai-icon';
import { discussionCommentWidth } from 'lecture_pages/components/discussion/discussion-insights';

type CallOutBoxProps = {
  icon: string;
  message: string;
};

// Taking the lecture component container styles to keep the consistency across the lecture page
const containerStyles = css`
  ${baseComponentStyles};
  display: flex;
  justify-content: flex-end;
`;

const callOutBoxstyles = css`
  display: flex;
  align-items: center;
  gap: ${halfSpacing}px;
  width: ${discussionCommentWidth};
  padding: ${standardSpacing}px;
  border-radius: ${halfSpacing}px;
  background: linear-gradient(48.41deg, rgba(96, 243, 171, 0.05) 0%, rgba(0, 204, 188, 0.05) 45%, rgba(0, 154, 192, 0.05) 100%);
`;

export const CallOutBox: React.FC<CallOutBoxProps> = ({
  icon,
  message,
}) => (
  <section css={containerStyles}>
    <div css={callOutBoxstyles}>
      <GradientAiIcon icon={icon} size='small' />
      <span className='text-body bold'>{message}</span>
    </div>
  </section>
);

export default CallOutBox;
