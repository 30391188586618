import { createReducer } from '@reduxjs/toolkit';
import { fetchAIGeneratedPrompts, fetchSinglePost, getKeyThemes, getPerspectives } from 'redux/actions/posts';
import { NovoAIErrorCodes } from 'redux/schemas/models/activity';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(fetchSinglePost.fulfilled, (state, action) => {
      const { postId } = action.meta.arg;
      const post = action.payload;
      Object.assign(state.models.posts[postId], post);
    })
    .addCase(fetchAIGeneratedPrompts.rejected, (state, action) => {
      state.app.generatedAIContent.discussionPrompts.loading = false;
    })
    .addCase(fetchAIGeneratedPrompts.pending, (state, action) => {
      state.app.generatedAIContent.discussionPrompts.loading = true;
    })
    .addCase(fetchAIGeneratedPrompts.fulfilled, (state, action) => {
      state.app.generatedAIContent.discussionPrompts.loading = false;
      state.app.generatedAIContent.discussionPrompts.errorCode = action.payload.errorCode;
      state.app.generatedAIContent.discussionPrompts.prompts = action.payload.prompts;
    })
    .addCase(getKeyThemes.rejected, (state, { payload }) => {
      state.app.generatedAIContent.discussionInsights.keyThemes.loading = false;
      // TODO: Nathaly - Validate how the error comes here
      state.app.generatedAIContent.discussionInsights.keyThemes.errorCode = payload as NovoAIErrorCodes;
    })
    .addCase(getKeyThemes.pending, (state, action) => {
      state.app.generatedAIContent.discussionInsights.keyThemes.loading = true;
      state.app.generatedAIContent.discussionInsights.keyThemes.errorCode = null;
    })
    .addCase(getKeyThemes.fulfilled, (state, { payload }) => {
      state.app.generatedAIContent.discussionInsights.keyThemes.loading = false;
      state.app.generatedAIContent.discussionInsights.keyThemes.errorCode = null;

      if (payload.status === 207) {
        state.app.generatedAIContent.discussionInsights.keyThemes.subscribeToPusher = true;
      } else {
        state.app.generatedAIContent.discussionInsights.keyThemes.subscribeToPusher = false;
        state.app.generatedAIContent.discussionInsights.keyThemes.data = payload.result;
      }
    })
    .addCase(getPerspectives.rejected, (state, { payload }) => {
      state.app.generatedAIContent.discussionInsights.perspectives.loading = false;
      // TODO: Nathaly - Validate how the error comes here
      state.app.generatedAIContent.discussionInsights.perspectives.errorCode = payload as NovoAIErrorCodes;
    })
    .addCase(getPerspectives.pending, (state, action) => {
      state.app.generatedAIContent.discussionInsights.perspectives.loading = true;
      state.app.generatedAIContent.discussionInsights.perspectives.errorCode = null;
    })
    .addCase(getPerspectives.fulfilled, (state, { payload }) => {
      state.app.generatedAIContent.discussionInsights.perspectives.loading = false;
      state.app.generatedAIContent.discussionInsights.perspectives.errorCode = null;

      if (payload.similar) {
        state.app.generatedAIContent.discussionInsights.perspectives.similar = payload.similar;
      }
      if (payload.different) {
        state.app.generatedAIContent.discussionInsights.perspectives.different = payload.different;
      }
    });
});
