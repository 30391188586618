import React from 'react';
import NvGradientBorderButton from 'shared/components/button/nv-gradient-border-button';
import t from 'react-translate';
import { css } from '@emotion/react';
import { baseComponentStyles } from 'lecture_pages/directives/components/base-lecture-component';
import { useSelector } from 'react-redux';
import { getAIDiscussionInsights } from 'redux/selectors/posts';
import { AIDiscussionInsights, PUSHER_DISCUSSION_INSIGHTS } from 'redux/schemas/models/post';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { getKeyThemes } from 'redux/actions/posts';
import NvGeneratingLoader from 'shared/components/loaders/nv-generating-loader';
import pusherService from 'shared/services/pusher-service';
import { getCurrentCourse } from 'redux/selectors/course';
import { config } from '../../../../config/pendo.config.json';
import GradientAiIcon from '../left-panel/novo-ai/components/shared/gradient-ai-icon';
import { discussionCommentWidth } from './discussion-insights';

type Props = {
  topicId: number;
};

const containerStyles = css`
  width: ${discussionCommentWidth};
  display: flex;
  justify-content: center;
`;

const styles = css`
  max-width: max-content;
`;

export const GenerateDiscussionInsights: React.FC<Props> = ({
  topicId,
}) => {
  const dispatch = useAppDispatch();
  const { id: courseId } = useSelector(getCurrentCourse);
  const { keyThemes }: AIDiscussionInsights = useSelector(
    getAIDiscussionInsights,
  );

  const handleOnClick = () => {
    wrapThunkAction(
      dispatch(getKeyThemes({ topicId })),
    ).then((action) => {
      if (keyThemes.subscribeToPusher) {
        const pusherChannel = pusherService.initializeCourseChannel(courseId);
        pusherChannel.bind(PUSHER_DISCUSSION_INSIGHTS, (pusherData) => {
          // TODO: Nathaly - Update the discussion component
          console.log('PUSHER_DISCUSSION_INSIGHTS ::: ', pusherData);
        });
      }
    });
  };

  if (keyThemes.loading) {
    return (
      <Container>
        <NvGeneratingLoader />
      </Container>
    );
  }

  if (!keyThemes.subscribeToPusher) {
    return (
      <Container>
        <NvGradientBorderButton
          onClick={handleOnClick}
          css={styles}
          className='ai-btn justify-content-center align-items-center py-2 px-5'
          dataQa={config.pendo.lectureEdit.aiDiscussionInsights.collapse}
        >
          <GradientAiIcon icon='gen-ai' size='small' className='pr-2' />
          <span className='text-large-regular bold'>
            {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.GENERATE()}
          </span>
        </NvGradientBorderButton>
      </Container>
    );
  }

  return null;
};

type ContainerProps = {
  children: React.ReactNode;
};

const Container: React.FC<ContainerProps> = ({ children }) => (
  <section css={baseComponentStyles} className='d-flex justify-content-end mt-4'>
    <div css={containerStyles}>
      {children}
    </div>
  </section>
);

export default GenerateDiscussionInsights;
