import { css } from '@emotion/react';
import React from 'react';
import { halfSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';

/**
 * Text loader component
 */
export type TextLoaderProps = {
  children: React.ReactNode;
  animate: boolean;
};

const animateStyles = css`
  background: -webkit-linear-gradient(48.41deg, #60F3AB 0%, #00CCBC 45%, #009AC0 100%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const noAnimateStyles = css`
  background: linear-gradient(
    to right,
    #60f3ab 20%,
    #14d1c2 40%,
    #60f3ab 60%,
    #14d1c2 80%
  );
  background-size: 200% auto;
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
`;

export const TextLoader: React.FC<TextLoaderProps> = ({ children, animate }) => (
  <div css={!animate ? animateStyles : noAnimateStyles} className='text-loader'>
    {children}
  </div>
);

/**
 * Grid loader component
 */
const gridStyles = css`
  display: flex;
  flex-direction: column;
  gap: ${halfSpacing}px;

  div {
    width: 100%;
    height: ${standardSpacing}px;
    border-radius: ${quarterSpacing}px;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: grid 2.2s linear infinite;
  }
  div:nth-of-type(1) {
    background: linear-gradient(48.41deg, rgba(96, 243, 171, 0.1) 0%, rgba(0, 204, 188, 0.1) 45%, rgba(0, 154, 192, 0.1) 100%);
    animation-delay: -0.8s;
  }
  div:nth-of-type(2) {
    background: linear-gradient(48.41deg, rgba(0, 154, 192, 0.1) 0%, rgba(0, 204, 188, 0.1) 55%, rgba(96, 243, 171, 0.1) 100%);
    animation-delay: -0.4s;
  }
  div:nth-of-type(3) {
    background: linear-gradient(48.41deg, rgba(96, 243, 171, 0.1) 0%, rgba(0, 204, 188, 0.1) 45%, rgba(0, 154, 192, 0.1) 100%);
    animation-delay: 0s;
  }
  @keyframes grid {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
  }
`;

export const GridLoader = () => (
  <div css={gridStyles}>
    <div />
    <div />
    <div />
  </div>
);

export default TextLoader;
