/* eslint-disable react/no-array-index-key */
import { css } from '@emotion/react';
import React from 'react';
import t from 'react-translate';
import { halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { baseComponentStyles } from 'lecture_pages/directives/components/base-lecture-component';
import { tealBlue } from 'styles/global_defaults/colors';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import { useSelector } from 'react-redux';
import { AIDiscussionInsights } from 'redux/schemas/models/post';
import { getAIDiscussionInsights } from 'redux/selectors/posts';
import NvUserAvatar from 'components/nv-user-avatar';
import { AngularServicesContext, ExposedAngularServices } from 'react-app';
import { getCurrentCourse } from 'redux/selectors/course';
import { Comment } from 'redux/schemas/models/comment';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import { config } from '../../../../config/pendo.config.json';
import GradientAiIcon from '../left-panel/novo-ai/components/shared/gradient-ai-icon';
import { TextLoader, GridLoader } from '../left-panel/novo-ai/components/shared/loaders';

// Getting the discussion comment width, which is the width for all the components of the discussion insights project
const userBadgeWidth = 80;
const leftPadding = halfSpacing;
export const discussionCommentWidth = `calc(100% - ${userBadgeWidth}px - ${leftPadding}px)`;

/**
 * Badge Component - It's shown only to the admin user
 */
type BadgeProps = {
  label: string;
  tooltip: string;
};

const badgeStyles = css`
  padding: 2px ${quarterSpacing}px;
  border-radius: ${quarterSpacing}px;
  background: linear-gradient(48.41deg, rgba(96, 243, 171, 0.1) 0%, rgba(0, 204, 188, 0.1) 45%, rgba(0, 154, 192, 0.1) 100%);
  color: ${tealBlue};
  cursor: default;
`;

const Badge: React.FC<BadgeProps> = ({ label, tooltip }) => (
  <NvTooltip
    enabled={!!tooltip}
    text={tooltip}
    placement='top'
  >
    <span css={badgeStyles} className='text-small font-weight-bold'>
      {label}
    </span>
  </NvTooltip>
);

/**
 * Perspective Component - It's shown only to the learner user
 */
type PerspectiveProps = {
  title: string;
  topicId: number;
  postId: number;
  comment: Partial<Comment>;
};

const perspectiveStyles = css`
  position: relative;
  cursor: pointer;
  padding: ${quarterSpacing}px ${halfSpacing}px;
  padding-left: ${halfSpacing + 4}px;
  border-radius: ${quarterSpacing}px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${halfSpacing}px;
  transition: background-color 0.218s ease-in;

  :hover {
    background-color: #00CCBC0D;
  }

  ::before {
    content: '';
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: linear-gradient(48.41deg, #60F3AB 0%, #00CCBC 45%, #009AC0 100%);
  }

  .left {
    display: flex;
    gap: ${halfSpacing}px;
  }
`;

const Perspective: React.FC<PerspectiveProps> = ({ title, topicId, postId, comment }) => {
  // Using the deprecated Context as required by the ResponsivelyEmbeddedAngularHTML component
  const angularServices: ExposedAngularServices = React.useContext(AngularServicesContext);
  const { id: catalogId } = useSelector(getCurrentCourse);

  const commentLink = React.useMemo(() => angularServices.$state.href(
    'comment-direct-link',
    {
      catalogId,
      topicId,
      postId,
      commentId: comment.id,
    },
  ), [comment.id]);

  return (
    <section>
      <h6 className='text-regular font-weight-bolder m-0 mb-2'>
        {title}
      </h6>

      <a
        css={perspectiveStyles}
        href={commentLink}
        rel='noreferrer'
        target='_blank'
      >
        <div className='left'>
          <NvUserAvatar
            tooltipEnabled={false}
            directToProfile={false}
            displayRoleBadge={false}
            user={comment.user}
            size='md'
            borderType='round'
          />

          <div>
            <p className='text-body font-weight-bolder m-0'>
              {comment.user.fullName}:
            </p>

            <ResponsivelyEmbeddedAngularHTML
              template={comment.body}
              angularServices={angularServices}
            />
          </div>
        </div>

        <NvIcon
          icon='arrow-right'
          size='xs-smallest'
          className='gray-2'
        />
      </a>
    </section>
  );
};

/**
 * Discussion Insights - It's shown to both admin and learner users with different content
 */
type DiscussionInsightsProps = {
  isLearner: boolean;
  topicId: number;
  postId: number;
  hasAiInsights: boolean;
};

const styles = css`
  width: ${discussionCommentWidth};
  padding: ${standardSpacing}px;
  border-radius: ${standardSpacing}px;
  background: linear-gradient(48.41deg, rgba(96, 243, 171, 0.05) 0%, rgba(0, 204, 188, 0.05) 45%, rgba(0, 154, 192, 0.05) 100%);
  display: flex;
  flex-direction: column;
  gap: ${standardSpacing}px;

  .header, .text-loader, .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header {
    .text-loader {
      gap: ${quarterSpacing}px;
    }

    .right {
      gap: ${standardSpacing}px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: ${standardSpacing}px;
  }
`;

export const DiscussionInsights: React.FC<DiscussionInsightsProps> = (
  {
    isLearner,
    hasAiInsights,
    topicId,
    postId,
  },
) => {
  const { keyThemes, perspectives }: AIDiscussionInsights = useSelector(
    getAIDiscussionInsights,
  );
  const [isExpanded, setIsExpanded] = React.useState(true);

  const loading = keyThemes.loading || perspectives.loading;

  const arrowDownStyles = css`
    transform: rotate(${isExpanded ? '-180deg' : '0deg'});
    transition: transform 0.218s ease-in;
  `;

  return (
    <section css={baseComponentStyles} className='d-flex justify-content-end mt-4'>
      <div css={styles}>
        <div className='header'>
          <div className='left'>
            <TextLoader animate={loading}>
              <GradientAiIcon icon='gen-ai' size='small' />
              <h6 className='text-body font-weight-bolder m-0'>
                {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.TITLE()}
              </h6>
            </TextLoader>
          </div>

          <div className='right'>
            {!(hasAiInsights && isLearner) && (
              <Badge
                label={t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.ADMIN_ONLY()}
                tooltip={t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.ADMIN_ONLY_TOOLTIP()}
              />
            )}
            <ClickableContainer
              className='d-flex align-items-center justify-content-center'
              aria-expanded={isExpanded}
              aria-controls='discussion_insights_body'
              onClick={() => setIsExpanded(!isExpanded)}
              data-qa={isExpanded ? config.pendo.lectureEdit.aiDiscussionInsights.collapse : config.pendo.lectureEdit.aiDiscussionInsights.expand}
            >
              <NvIcon
                icon='arrow-down'
                size='small'
                className='bold'
                css={arrowDownStyles}
              />
            </ClickableContainer>
          </div>
        </div>

        {isExpanded && (
          <div id='discussion_insights_body' className='body'>
            {loading && <GridLoader />}

            {(!loading && isLearner) && (
              <h5 className='m-0 mb-2'>
                {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.LEARNER_SUBTITLE()}
              </h5>
            )}

            {!loading && (
              <div className='key-themes'>
                {keyThemes.data.length > 0 && (
                <>
                  <h6 className='text-regular font-weight-bolder m-0 mb-2'>
                    {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.KEY_THEMES()}
                  </h6>
                  <ul>
                    {keyThemes.data.map((keyTheme, index) => (
                      <li
                        key={`key-theme-${index}`}
                        className='text-body'
                      >
                        {keyTheme}
                      </li>
                    ))}
                  </ul>
                </>
                )}
              </div>
            )}

            {(!loading && isLearner && perspectives.similar) && (
              <Perspective
                title={t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.SIMILAR_PERSPECTIVE()}
                topicId={topicId}
                postId={postId}
                comment={perspectives.similar}
              />
            )}

            {(!loading && isLearner && perspectives.different) && (
              <Perspective
                title={t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.DIFFERENT_PERSPECTIVE()}
                topicId={topicId}
                postId={postId}
                comment={perspectives.different}
              />
            )}

            {(!loading && hasAiInsights && !isLearner) && (
              <p className='m-0 text-small font-weight-bold text-gray-2'>
                {t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.NOT_ADMIN_ONLY()}
              </p>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default DiscussionInsights;
