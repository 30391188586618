import { css } from '@emotion/react';
import React, { useState, useCallback, useMemo, useContext, useRef, useEffect, MutableRefObject } from 'react';
import t from 'react-translate';
import { NvDropdownOption, NvDropdownProps } from 'shared/components/inputs/nv-dropdown';
import NvTooltip, { TextAlign } from 'shared/components/nv-tooltip';
import { LectureComponentProps, LecturePageMode } from 'lecture_pages/components';
import { useSelector } from 'react-redux';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import _, { isEmpty } from 'underscore';
import { NLecturePage, NovoAIItemType } from 'redux/schemas/models/lecture-page';
import { quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import store, { useAppDispatch } from 'redux/store';
import { getIsTodo, getIsActivity, getIsRequiredForCompletion, getPayload } from 'redux/selectors/lecture-components';
import { ComponentTrueType, ComponentType, LectureComponent, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { deleteLectureComponent } from 'redux/actions/lecture-components';
import getComponentMetadata from 'lecture_pages/components/data';
import { getFlatCourseAliases } from 'redux/selectors/course';
import merge from 'lodash/merge';
import ConditionalWrapModalWorkflow from 'lecture_pages/components/workflows/modal-workflow';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import { AngularServicesContext } from 'react-app';
import { denormalize } from 'normalizr';
import { LectureComponentSchema } from 'redux/schemas/api/lecture-components';
import { gray6, gray5, gray3, primary, warning } from 'styles/global_defaults/colors';
import { ConditionalWrap } from 'components/conditional-wrap';
import { ActivityType, innerPayloadKey, VideoPayload } from 'redux/schemas/models/activity';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import useWindowResize from 'shared/hooks/use-window-resize';
import { hideAddUI } from 'lecture_pages/templates/components/nv-add-component';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { addAlertMessage } from 'redux/actions/alert-messages';
import ActivityTypesDropdown from 'lecture_pages/components/activity-types-dropdown';
import { isRtl } from 'styles/global_defaults/media-queries';
import { Placement } from 'react-bootstrap/Overlay';
import { LecturePagePreviewContext } from 'lecture_pages/components/lecture-page-preview-modal';
import useCreateLectureComponent from 'lecture_pages/hooks/use-lecture-component';
import LectureComponentEditDropdown, { LectureComponentDropdownMode } from '../lecture-component-edit-dropdown';
import ComponentReorderButtons from '../component-reorder-buttons';
import BaseLectureComponentContext from './context';
import RegenerateAIComponent from '../regenerate-ai-component-button';

// A list of properties shared between the BaseLectureComponent and the inner component. This should be reserved for values that need to be provided by the inner component but accessed by the Base Lecture component.
export type BaseComponentState = {
  sharedProps: SharedLectureComponentProps,
  setSharedProps: (props: SharedLectureComponentProps) => void,
  /** Triggers UX for deleting a component from the lecture page */
  deleteComponent: () => void,
  /** Triggers UX for moving a component to a different lecture page */
  moveComponent: () => void,
  /** Triggers UX for copying a component to this or another lecture page */
  copyComponent: () => void,
  extraContentAreaRef?: MutableRefObject<any>,
  containerRef: React.MutableRefObject<HTMLDivElement>,
};

export type SharedLectureComponentProps = {
  isFullWidth: boolean,
  extraOptions: {
    /** Where to add the extra dropdown options to the default list.
   * 'prepend' inserts them at the top, 'append' inserts them at the bottom, and
   * 'custom' replaces the entire list */
    mode: 'prepend' | 'append' | 'custom',
    /** Additional dropdown options components can specify in addition to the default
   * Move to, Copy to, and Delete actions */
    options?: NvDropdownOption[],
    /** Whether to highlight the last selected option with a checkmark via the NvDropdown's
   * showSelectedIndicator prop. The default options are disabled from showing active states. */
    showActive?: boolean,
    /** The index of which item should start as selected */
    initialSelectedIndex?: number,
    editEnabled?: boolean,
    getEditOption?: (optionName?: string, isDisabled?: boolean, disabledProps?: EditOptionDisabledProps) => NvDropdownOption,
    /** Forces the dropdown to be displayed. Is only null if a component overwrites
     * this extraOptions object */
    forceShow?: () => void,
    forceShowActivities?: () => void,
    forceHideActivities?: () => void,
    renderOnMount?: boolean,
  },
  /** If set, will make the checkmark icon display a dropdown with the given properties */
  todoOptions?: NvDropdownProps,
  setTodo?: (newTodoStatus: boolean) => void,
  deleteText?: string,
  deleteConfirmationMessage?: string,
  openBasicModal?: () => void,
  deleteConfirmationTitle?: string;
};

export type BaseLectureComponentProps<C extends ComponentTrueType = any> = {
  containerRef: LectureComponentProps['containerRef'],
  lectureComponent: NLectureComponent<C>,
  currentLecturePage: NLecturePage,
  RenderLectureComponent: (props: LectureComponentProps<C>) => JSX.Element
};


type EditOptionDisabledProps = {
  disabledTooltip?: string,
  tooltipTextAlign?: TextAlign,
  tooltipPlacement?: Placement
};

const COMPONENT_AREA_WIDTH = 800;

export const baseComponentStyles = css`
  max-width: ${COMPONENT_AREA_WIDTH}px;
  margin: auto;
  position: relative;
`;

const toolsPanelStyles = css`
  position: absolute;
  /** Place this outside of the 800px max area for the lecture components.
  This copies what we do in the angularjs app */
  right: -33px;
  height: 100%;
  top: 0;
  background-color: white;
  min-height: ${standardSpacing}px;
  /** TODO: Play with this for the image component 'Full' formatting */
  /* opacity: 0.5; */
  width: 20px;
  border-left: 1px solid;
  border-color: ${gray6};
  display: flex;
  flex-direction: column;

  & > * {
    padding-left: ${quarterSpacing}px;
    padding-bottom: ${quarterSpacing}px;
    &:not(:first-of-type) {
      margin-top: ${standardSpacing}px;
    }
  }
  .icon-move-up, .icon-move-down {
    color: ${gray3};
    &.active {
      color: ${primary};
      &:hover {
        cursor: pointer;
      }
    }
  }
  // to-do checkmark
  .todo-toggle.icon-check {
    color: ${gray6};
    cursor: pointer;
    &.color-highlight {
      color: ${warning};
    }
  }

  .todo-options-dropdown-menu {
    min-width: 280px !important;
  }
`;

/** The base higher order component that wraps all React Lecture components. Renders the edit dropdown,
 * controls the width of the component, and sets up the component context */
export const BaseLectureComponent = (props: BaseLectureComponentProps) => {
  const dispatch = useAppDispatch();
  const { RenderLectureComponent, lectureComponent, currentLecturePage } = props;
  const params = useLecturePageParams();
  const { fireUpdateComponent } = useCreateLectureComponent();
  const angularServices = useContext(AngularServicesContext);
  const previewParams = useContext(LecturePagePreviewContext);

  const currentCatalogId = useSelector(state => state.app.currentCatalogId);
  const isActivity = useSelector(state => getIsActivity(state, lectureComponent.id));
  const isTodo = useSelector(state => getIsTodo(state, lectureComponent.id));
  const isRequiredForCompletion = useSelector(state => getIsRequiredForCompletion(state, lectureComponent.id));
  const payload = useSelector(state => getPayload(state, lectureComponent.id));
  const courseAliases = useSelector(state => getFlatCourseAliases(state, currentCatalogId));
  const leftPanelView = useSelector(state => state.app.lecturePage.leftPanelView);
  const newGeneratedAIComponent = useSelector(state => state.app.newGeneratedAIComponent);

  const [dropdownMode, setDropdownMode] = useState<LectureComponentDropdownMode>('normal');
  const [showEditDropdown, setShowEditDropdown] = useState<boolean>(false);
  const [showActivityTypesDropdown, setShowActivityTypesDropdown] = useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>();
  const widthRef = useRef<HTMLDivElement>(null);
  const editBasicRef = useRef<HTMLDivElement>();
  const extraContentAreaRef = useRef(null);

  const metadata = useMemo(() => getComponentMetadata(lectureComponent.trueType), [lectureComponent.trueType]);
  const angularLectureComponent = angularServices.$scope[`lectureComponent${lectureComponent.id}`];
  const isResizing = angularLectureComponent?.externalTool?.isResizing;
  // This means the component is called from a preview modal, so it should be in read only mode
  const isPreview = !isEmpty(previewParams);
  const mode = isPreview ? LecturePageMode.VIEW : params.mode;

  const deleteComponent = useCallback(() => {
    dispatch(deleteLectureComponent({
      catalogId: currentCatalogId,
      lectureComponent,
      lecturePageId: currentLecturePage.id,
    })).then((response) => {
      if (deleteLectureComponent.rejected.match(response)) {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        }));
      } else {
        // TODO: Fix this `as any`. For some reason TS insists this lecture component is a `never`
        metadata.afterDelete?.(dispatch, lectureComponent as any, { angularServices });
      }
    });
  }, [currentCatalogId, dispatch, metadata, currentLecturePage.id, lectureComponent, angularServices]);

  /** Creates an NvDropdown option that can be clicked to show the
   * correct workflow state to edit an existing lecture component.
   * So, modal workflow components will show a modal with lecture component
   * info prefilled, file workflows will show a file select dialog, etc. */
  const getEditOption = (optionName?: string, isDisabled?: boolean, disabledProps?: EditOptionDisabledProps): NvDropdownOption => {
    // TODO: Delete this once we no longer have to runtime calculate the true type of components
    const { disabledTooltip = '', tooltipTextAlign, tooltipPlacement = 'left' } = disabledProps || {};
    const { models } = store.getState();
    const denormedLC: LectureComponent = denormalize(lectureComponent, LectureComponentSchema, models);
    return {
      type: 'custom',
      customItem: (
        <div>
          {(isDisabled) ? (
            <NvTooltip
              enabled={!!disabledTooltip}
              text={disabledTooltip}
              textAlign={tooltipTextAlign}
              placement={tooltipPlacement}
            >
              <div style={{ cursor: 'default', color: `${gray5}` }} className='bs4-dropdown-item disabled' role='button' aria-disabled='true' ref={editBasicRef}>
                { optionName ?? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS()}
              </div>
            </NvTooltip>
          ) : (
            <ConditionalWrapModalWorkflow
              metadata={metadata}
              mode='edit'
              save={fireUpdateComponent}
              componentType={denormedLC.trueType}
              // TODO: Fix this cast
              lectureComponent={lectureComponent as unknown as LectureComponent}
            >
              <div className='bs4-dropdown-item' ref={editBasicRef} onClick={() => setShowEditDropdown(false)}>
                <div>
                  { optionName ?? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS()}
                </div>
              </div>
            </ConditionalWrapModalWorkflow>
          )}
        </div>
      ),
    };
  };

  const setTodo = useCallback((newTodoStatus: boolean) => {
    const doSetTodo = () => {
      let useRootIsTodo = false;
      let componentPayload: typeof payload = null;

      // Uniquely, video/audio components only send a subset of their payload info when being set as to-do. Failing to remove this 'extra' data causes the backend to crash with this error: https://app.honeybadger.io/projects/1426/faults/79512160
      // TODO: This may also affect other endpoints; consider moving this special-case fix to a more general location. They also set the 'todo' status on the lecture component itself instead of the payload, even though current to-do status is calculated by looking at the 'isTodo' on each video item
      if (lectureComponent.trueType === ComponentType.VIDEO || lectureComponent.trueType === ComponentType.AUDIO) {
        useRootIsTodo = true;
        componentPayload = (payload as VideoPayload['lectureVideos']).map((v, i) => ({
          video: v.video,
          index: i,
          id: v.id,
        }));
      } else if (lectureComponent.trueType === ComponentType.TEAM_FORMATION || lectureComponent.trueType === ComponentType.EXERCISE || lectureComponent.trueType === ComponentType.QUIZ || lectureComponent.trueType === ComponentType.TIMED_QUIZ || lectureComponent.trueType === ComponentType.SURVEY) {
        const { name, ...rest } = payload;
        componentPayload = {
          ...rest,
          isTodo: !useRootIsTodo ? newTodoStatus : undefined,
        };
      } else if (lectureComponent.trueType === ComponentType.PROGRESSIVE_QUIZ) {
        componentPayload = {
          isTodo: !useRootIsTodo ? newTodoStatus : undefined,
        };
      } else if (lectureComponent.trueType === ComponentType.VIDEO_PRACTICE_FEEDBACK) {
        // This is added to avoid response from backend troubles when not sending the activity type when marking as ToDo [NOV-86300]
        componentPayload = {
          ...payload,
          activityType: ActivityType.VIDEO_PRACTICE,
          isTodo: !useRootIsTodo ? newTodoStatus : undefined,
        };
      } else {
        componentPayload = {
          // You *must* provide the existing payload data in this call or the backend will (in some cases) set payload properties as 'null'!
          ...payload,
          isTodo: !useRootIsTodo ? newTodoStatus : undefined,
        };
      }

      dispatch(updateLectureComponent({
        ...params,
        lecturePageId: lectureComponent.lecturePageId,
        componentData: {
          id: lectureComponent.id,
          index: lectureComponent.index,
          type: lectureComponent.type,
          isTodo: useRootIsTodo ? newTodoStatus : undefined,
          // Obtain the property used for the payload on this component and set `isTodo` on it. Type checking does not yet work here b/c we don't have a common type that defines `isTodo`
          [innerPayloadKey[lectureComponent.type]]: componentPayload,
        },
      }));
    };

    if (!newTodoStatus && isRequiredForCompletion) {
      dispatch(openConfirmationDialog({
        title: t.LECTURE_PAGES.COMPONENTS.TODO_MODAL.REMOVING({
          // Not all components have a lowercase translation name; default to caps if not present
          componentName: (metadata.descriptionLowercase ?? metadata.description)(courseAliases),
          ...courseAliases,
        }),
        cancelText: t.FORM.CANCEL(),
        confirmText: t.FORM.YES_SURE(),
        onConfirm: doSetTodo,
      }));
    } else {
      doSetTodo();
    }
  }, [courseAliases, dispatch, isRequiredForCompletion, lectureComponent.id,
    lectureComponent.index, lectureComponent.lecturePageId, lectureComponent.type,
    metadata.description, metadata.descriptionLowercase, params, payload, lectureComponent.trueType,
  ]);

  /** Triggering a click event in the edit option in the admin menu for
   * opening the edit basic modal from an angular jade.
   * Call setSharedProps with renderOnMount in the angular controller to render
   * the dropdown menu in the DOM before the first time it is shown for setting the ref value */
  const openBasicModal = () => {
    editBasicRef?.current?.click();
  };

  const [sharedComponentProps, setSharedComponentProps] = useState<SharedLectureComponentProps>({
    isFullWidth: false,
    extraOptions: {
      mode: 'prepend',
      options: null,
      editEnabled: false,
      getEditOption,
      forceShow: () => setShowEditDropdown(true),
      forceShowActivities: () => setShowActivityTypesDropdown(true),
      forceHideActivities: () => setShowActivityTypesDropdown(false),
    },
    setTodo,
    openBasicModal,
    deleteText: t.LECTURE_PAGES.COMPONENTS.DELETE(),
  });

  /* Delete overlay adapted from  app\lecture_pages\templates\components\delete-confirmation-overlay.jade */
  const showDeleteConfirmation = useCallback(() => {
    const componentName = (metadata.descriptionLowercase ?? metadata.description)(courseAliases);
    let bodyText = metadata.deleteWarning?.(courseAliases);

    if (!bodyText) {
      if (isActivity) {
        bodyText = t.LECTURE_PAGES.COMPONENTS.DELETION_MODAL.ACTIVITY_DESCRIPTION(componentName);
      } else {
        bodyText = t.LECTURE_PAGES.COMPONENTS.DELETION_MODAL.SIMPLE_DESCRIPTION();
      }
    }

    dispatch(openConfirmationDialog({
      // TODO: Make a new equivalent of descriptionKey and other model props
      title: sharedComponentProps.deleteConfirmationTitle ?? t.LECTURE_PAGES.COMPONENTS.DELETION_MODAL.TITLE(componentName),
      bodyText,
      cancelText: t.FORM.CANCEL(),
      confirmText: t.FORM.YES_SURE(),
      onConfirm: deleteComponent,
    }));
  }, [courseAliases, deleteComponent, dispatch, isActivity, metadata, sharedComponentProps.deleteConfirmationTitle]);

  /** Components can configure themselves to use the full width of the container for sizing. To accomplish this, we measure the
   * width of the lecture page content area and set the component to use that matching size. We then calculate an offset to fix the
   * component's position since it's being rendered in a max-width === 800px space */
  const setComponentFullWidth = useCallback(() => {
    if (newGeneratedAIComponent?.isNew) {
      // Only if it's a component generated by AI and it's not new (regenerated), then it continues checking the full size property.
      return;
    }
    if (!sharedComponentProps.isFullWidth) {
      return;
    }

    if (!props.containerRef.current) {
      return;
    }

    const containerWidth = props.containerRef.current.getBoundingClientRect().width;

    const sizeDiff = containerWidth - COMPONENT_AREA_WIDTH;

    const containerLeftPadding = window.getComputedStyle(props.containerRef.current).paddingLeft;
    // Always use an offset at least the amt of the left padding (20) when we're larger than 800px. Otherwise, measure the left padding used in mobile view and offset with that.
    const leftOffset = sizeDiff > 0 ? Math.max(sizeDiff / 2, 20) : containerLeftPadding.substr(0, containerLeftPadding.length - 2);

    if (widthRef.current) {
      widthRef.current.style.width = `${containerWidth}px`;
      if (isRtl()) {
        widthRef.current.style.marginRight = `-${leftOffset}px`;
      } else {
        widthRef.current.style.marginLeft = `-${leftOffset}px`;
      }
    }
  }, [props.containerRef, sharedComponentProps.isFullWidth, newGeneratedAIComponent]);

  useWindowResize(() => {
    setComponentFullWidth();
  }, 100, !sharedComponentProps.isFullWidth);

  useEffect(() => {
    setComponentFullWidth();
  }, [setComponentFullWidth]);

  const setCopyComponent = useCallback(() => {
    setDropdownMode('copy');
  }, []);

  const setMoveComponent = useCallback(() => {
    setDropdownMode('move');
  }, []);

  const baseLectureComponentContextValue = useMemo<BaseComponentState>(() => ({
    sharedProps: sharedComponentProps,
    setSharedProps: setSharedComponentProps,
    deleteComponent: showDeleteConfirmation,
    moveComponent: setMoveComponent,
    copyComponent: setCopyComponent,
    extraContentAreaRef,
    containerRef,
  }),
  [setCopyComponent, setMoveComponent, sharedComponentProps, showDeleteConfirmation]);

  const customTodoOptions = !!sharedComponentProps.todoOptions;
  const selectedActivityType = sharedComponentProps?.todoOptions?.items?.findIndex((i: any) => i.selected);

  /** The icon-check button has one of two behaviors depending on the shared props configuration:
   * 1. If customTodoOptions is set, will display those todo options when clicked, this item is not marked as to-do. If it
   * is marked as todo, it will clear the to-do status
   * 2. Simply toggles to-do status on/off if customTodoOptions is not set */
  const onToggleTodoClicked = useCallback(() => {
    if (customTodoOptions) {
      if (isTodo) {
        setTodo(false);
        setShowActivityTypesDropdown(false);
      } else {
        if (isActivity) setTodo(true);
        setShowActivityTypesDropdown(true);
      }
    } else {
      setTodo(!isTodo);
    }
  }, [customTodoOptions, isTodo, setTodo]);

  const onEditDropdownToggle = useCallback((nextShow) => {
    setShowEditDropdown(nextShow);
    if (!nextShow) {
      setDropdownMode('normal');
    } else if (leftPanelView !== 'outline') {
      hideAddUI(dispatch);
    }
  }, [dispatch, leftPanelView]);

  const todoTooltipText = () => {
    if (isTodo) {
      return t.LECTURE_PAGES.COMPONENTS.TODO.REMOVE();
    }
    return !customTodoOptions ? t.LECTURE_PAGES.COMPONENTS.TODO.ADD() : '';
  };

  const canShowComponentAiRegeneration = (lc: BaseLectureComponentProps['lectureComponent']) => (
    lc.aiOrigin && lc.aiOrigin !== NovoAIItemType.NO_AI
  );

  const editTools = !isPreview && (mode === LecturePageMode.EDIT || mode === LecturePageMode.LINKED_EDIT) ? (
    <React.Fragment>
      <LectureComponentEditDropdown
        lectureComponent={lectureComponent}
        currentLecturePage={currentLecturePage}
        show={showEditDropdown}
        onToggle={onEditDropdownToggle}
        catalogId={currentCatalogId}
        dropdownMode={dropdownMode}
      />
      {
        canShowComponentAiRegeneration(lectureComponent) && (
          <RegenerateAIComponent
            lectureComponent={lectureComponent}
          />
        )
      }
      {!params.currentCourseIsCollection && isActivity && (
        <ConditionalWrap
          condition={(!isTodo || showActivityTypesDropdown) && customTodoOptions}
          wrap={(children) => (
            <ActivityTypesDropdown
              show={showActivityTypesDropdown}
              sharedProps={sharedComponentProps}
              tooltip={t.LECTURE_PAGES.COMPONENTS.TODO.ADD()}
              initialIndex={selectedActivityType}
              onCollapse={() => setShowActivityTypesDropdown(false)}
            >
              {children}
            </ActivityTypesDropdown>
          )}
        >
          {/* Only add the 'Add' tooltip if it's not been added via a custom dropdown above */}
          <NvTooltip text={todoTooltipText()}>
            <div>
              <div
                className={`todo-toggle icon icon-check icon-xss-smallest ${isTodo ? 'color-highlight' : ''}`}
                  // Only fire setTodo on click if it is not being delegated to a todo options callback
                onClick={() => onToggleTodoClicked()}
                data-qa='mark-as-todo-toggle'
              />
            </div>
          </NvTooltip>
        </ConditionalWrap>
      )}
    </React.Fragment>
  ) : null;

  // This is to avoid showing the <NvGeneratingLoader> and the actual component simultaneously when regenerating an AI Gen Component, and makes the component take the updated info from BE.
  if (newGeneratedAIComponent?.index === lectureComponent.index && !newGeneratedAIComponent.isNew) {
    return null;
  }

  return (
    <BaseLectureComponentContext.Provider value={baseLectureComponentContextValue}>
      {/* lecture-component-container is a class used by a small number of Angularjs components, and should be removed long-term */}
      <div
        ref={containerRef}
        css={baseComponentStyles}
        // Only add bottom margin when not in edit mode. In edit mode the nv add components add sufficient spacing. Note that this class name is historical and is used by many old angularjs component styles
        className={`lecture-component-container ${mode !== LecturePageMode.EDIT ? 'mb-4' : ''} ${lectureComponent.trueType === ComponentType.ATTACHMENT ? 'attachment-lecture-component-container' : ''}`}
        // This id is used as the target for scrollTo() actions
        id={getLectureComponentDomId(lectureComponent)}
        /** Spot-fix for https://novoed.atlassian.net/browse/NOV-68494 */
        style={mode === LecturePageMode.REORDER ? {
          minHeight: '50px',
        } : undefined}
      >
        <div ref={widthRef}>
          <RenderLectureComponent
            containerRef={props.containerRef}
            lectureComponent={lectureComponent}
            currentLecture={currentLecturePage}
            mode={mode}
          />
        </div>
        {mode !== LecturePageMode.VIEW && !isPreview
          && (
          <div css={toolsPanelStyles} className={`tools-panel ${isResizing ? 'hidden' : ''}`}>
            {editTools}
            {mode === LecturePageMode.REORDER && (
              <ComponentReorderButtons
                currentLecturePage={currentLecturePage}
                lectureComponent={lectureComponent}
              />
            )}
          </div>
          )}
      </div>
      <div ref={extraContentAreaRef} />
    </BaseLectureComponentContext.Provider>
  );
};

/** The DOM id attribute text for a lecture component */
export const getLectureComponentDomId = (lectureComponent: BaseLectureComponentProps['lectureComponent']) => `lecture-component-${lectureComponent.id}`;
